import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ECollection } from '../../enums/ECollection';
import { EModelApiPaths } from '../../enums/EModelApiPaths';
import { IAPIResponse } from '../../enums/types';
import { IClaimRequestBody_P } from '../../interfaces/IClaimRequestBody';
import { IClaimResponse } from '../../interfaces/IEdiNewInterface';
import { IInvoiceAttest } from '../../interfaces/IInvoice';
import { UtilsService } from '../utils.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class RosterService extends BaseService {
    constructor(public http: HttpClient, public utilsService: UtilsService, public userService: UserService, public authService: AuthService) {
        super(http, userService, utilsService, `/schema/${environment.USER_PATH}/${environment.INS}/${environment.DB}/${ECollection.MAS_VENDOR_FILE_DATA}`);
    }

    async getRosterData(body: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http
            .post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.GET_ROSTER_DATA, encryptedData, this.userService.getRequestOptions())
            .pipe(
                tap((tempResp) => {
                    if (!(tempResp.success && tempResp.data && tempResp.data.trips)) throw new Error('MAS API call failed.');
                }),
                retry(3)
            )
            .toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async attestInvoiceSubmit(body: IInvoiceAttest) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.MAS_ATTEST_INVOICE_SUBMIT, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async emednyClaim(body: IClaimRequestBody_P): Promise<IClaimResponse> {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.EMEDNY_CLAIM, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async emednySummaryReports(body: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.EMEDNY_SUMMARY_REPORTS, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async emednyReportsDetails(body: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.EMEDNY_REPORTS_DETAILS, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }
}
