// collection
export enum ECollection {
    USERS = 'rb_users',
    CUSTOMERS = 'rb_customers',
    MAS_VENDOR_FILE_DATA = 'rb_mas_vendor_file_data',
    DRIVER_LISTS = 'rb_driver_lists',
    VEHICLE_LISTS = 'rb_vehicle_lists',
    RB_SET_DRIVER_VEHICLES = 'rb_set_driver_vehicles',
    RB_UPLOAD_DRIVER_VEHICLES = 'rb_upload_driver_vehicles',
    RB_CLAIM_DONES = 'rb_claim_dones',
    RB_CYCLE_CALENDARS = 'rb_cycle_calendars',
    RB_CLAIM_TRACKINGS = 'rb_claim_trackings',
    RB_PAYMENT_TRACKS = 'rb_payment_tracks',
    RB_NOTIFICATIONS = 'rb_notifications',
    RB_INVOICES = 'rb_invoices',
    RB_SUBMITTED_EDIS = 'rb_submitted_edis',
}
