import { EventEmitter, Injectable } from '@angular/core';
import { INotification, INotification_P, INotification_S } from '../interfaces/INotification';
import { environment } from '../../environments/environment';
import { AuthService } from './models/auth.service';
import { UserService } from './models/user.service';

@Injectable({ providedIn: 'root' })
export class SocketService {
    socket: WebSocket;
    events = {
        notification: new EventEmitter<INotification>(),
    };

    shouldConnectAgain = true;

    registeredEvents = [];

    constructor(private authService: AuthService,
                private userService: UserService) {
    }

    getConnection(userId: string) {
        const accessAMToken = this.userService.amUser.accessAMToken || '';
        const accessToken = this.authService.gUser.accessToken || '';
        if (!accessToken || !accessAMToken) return;

        const socketS = this;
        const ws = new WebSocket(`${environment.RB_BE_WS_HOST_PORT}?authTokenInfo=${environment.authTokenInfo}&x-am-authorization=${this.userService.amUser.accessAMToken}&x-am-user-authorization=${this.authService.gUser.accessToken}`);
        this.socket = ws;

        function sendRegisterEvent() {
            send(<any>{
                objType: 'REGISTER',
                onEvents: [
                    {
                        eventType: 'INSTANCES',

                        apiPath: environment.USER_PATH,
                        instance: environment.INS,
                        database: environment.DB,
                        collection: 'rb_notifications',
                        apiName: 'SCHEMA_POST_BULK_INSERT',
                        getEventData: true,

                        condition: {
                            conditionType: 'RESPONSE',
                            criteria: <INotification_P>{
                                userId,
                                // active: true, // We want created but active false notifications also.
                            },
                        },
                        select: <INotification_S>{}, // get everything.

                    },
                ],
            });

            // setTimeout(() => {
            //     console.log('unregister');
            //     send({
            //         objType: 'UNREGISTER',
            //         onEvents: [registeredEvents[0].eventId],
            //     });
            // }, 5000);
        }

        function send(data) {
            ws.send(JSON.stringify(data));
        }

        ws.onmessage = function (event) {
            const serverResp = JSON.parse(event.data);
            // console.log(serverResp);
            if (serverResp.type === 'NOTIFICATION' && serverResp.response.eventData.userId === socketS.authService.getUserId()) socketS.events.notification.next(serverResp.response.eventData);

            if (serverResp.type === 'CONNECTED' && serverResp.response.connected) sendRegisterEvent();
            if (serverResp.validOnEvents && serverResp.validOnEvents.length) {
                for (const validEvent of serverResp.validOnEvents) {
                    socketS.registeredEvents.push(validEvent);
                }
            }
        };

        ws.onclose = (event) => {
            if (this.shouldConnectAgain) {
                setTimeout(() => {
                    this.getConnection(userId);
                }, 5000);
            }
        };
    }

    disconnect() {
        this.shouldConnectAgain = false;
        this.socket.close(1000, 'Deliberate Connection');
        setTimeout(() => {
            this.shouldConnectAgain = true;
        }, 2000);
    }
}
