import { Component, OnInit } from '@angular/core';
import { EConfirmationDialogStatus } from '../../enums/EConfirmationDialogStatus';
import { NotificationService } from '../../services/notification.service';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.c.html',
})
export class ConfirmationDialogC implements OnInit {
    EConfirmationDialogStatus = EConfirmationDialogStatus;

    constructor(
        public notificationS: NotificationService,
    ) {
    }

    ngOnInit() {
    }

    public close(status: EConfirmationDialogStatus) {
        this.notificationS.dialog.confirm.confirmationDialogOpened = false;
        this.notificationS.dialog.confirm.confirmationDialogStatus.emit(status);
    }
}
