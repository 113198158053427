export enum EType {
    string = 'string',
    number = 'number',
    boolean = 'boolean',
}

export enum ESendingToSchema {
    DB = 'DB',
    USER = 'USER',
}

export enum EAuthTokenType {
    AM = 'AM', // API Maker user token
    AM_DB = 'AM_DB', // User database entry
}

export enum EAPIAccessType {
    IS_PUBLIC = 'IS_PUBLIC',
    TOKEN_ACCESS = 'TOKEN_ACCESS',
}

export enum EEncryptionAlgorithm {
    AES = 'AES',
    RC4 = 'RC4',
    TRIPLEDES = 'TRIPLEDES',
}

export enum EHashingAlgorithms {
    SHA256 = 'SHA256',
}

export interface IThirdPartyAPIIdentity {
    apiBundleName: string; // npmPackageName // apiBundleName
    // developedByUsername: string;
    apiVersionName: string;
    name: string;
}

export interface ICollectionIdentity {
    instance: string;
    database?: string;
    collection?: string;
    table?: string;
}

export interface IAMGlobalBaseParams extends ICollectionIdentity {
    headers?: any;
}

export interface IApiParamsGetAll extends IAMGlobalBaseParams {
    queryParams?: IGetAllQueryParams;
}

export interface IGetAllQueryParams {
    find?: any;
    skip?: number;
    limit?: number;
    sort?: any;
    select?: any;
    getTotalCount?: boolean;
    [key: string]: any;
}

export interface IDecryptDataFETransfer {
    dataEncFE: string;
}

export interface IDecryptDataFETransferPayload {
    data: any;
    createdAt: Date;
}

export interface IAPIResponse<T> {
    data?: T;
    totalCount?: number;
    encryptedData?: string;
    errors?: IResponseError[];
    logs?: any[];
    meta?: IAPIResponseMeta;
    stackTraceErrors?: any[];
    statusCode: EStatusCode;
    success: boolean;
    warnings?: IResponseError[];
}

export interface IAPIResponseMeta {
    executionTime?: string;
    executionPlan?: any[]; // IMongodbExplainPlan[];
    // apiAccessGroups?: IAPIAccessGroupMeta[];
    // runBy?: ICodeRunByResponse[];
}

export interface IResponseError {
    type?: EErrorType;
    field?: string;
    message?: string;
    action?: string; // we can give message in this to solve this error or issue.
    value?: any;
    code?: EStatusCode;
    systemMessage?: string;
    dataIndex?: number;
    stack?: any; // it might be present.
    apiCallSequence?: string[];
}

export enum EStatusCode {
    OK = 200,
    NO_CONTENT = 204, // do not use this because it will not send our data with null.
    CREATED = 201,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403, // client is authenticated but doesn't have access plus CORS error
    RESOURCE_NOT_FOUND = 404, // convert 405(Method not allowed) in this 404.
    INTERNAL_SERVER_ERROR = 500,
}

export enum EErrorType {
    required = 'required',
    min = 'min',
    max = 'max',
    minLength = 'minLength',
    maxLength = 'maxLength',
    unique = 'unique',
    uniqueCombination = 'uniqueCombination', // multiple keys unique violated
    invalidValue = 'invalidValue',
    schemaKeyNotFound = 'schemaKeyNotFound',
    schemaNotFound = 'schemaNotFound',
}

export interface IDeleteAPIResponse {
    deletedRows: any[];
    deletedRowsCount: number;
    ids: any[];
}

export enum EHeader {
    CONTENT_TYPE = 'content-type',
    ACCEPT_ENCODING = 'accept-encoding', // default br compression, identity = no compression, if accept-encoding header is not found br will be used. user can any of these values ['br', 'deflate', 'gzip', 'identity']
    NO_COMPRESSION_STD = 'x-no-compression', // standard compression header is supported by us.

    AUTHORIZATION_AM = 'x-am-authorization', // if API is not public then this token is mandatory.
    AUTHORIZATION_AM_USER = 'x-am-user-authorization', // used tor EAuthTokenType

    ORIGIN = 'origin',
    AUTHORIZATION = 'authorization',
    META = 'x-am-meta', // true, false
    SECRET = 'x-am-secret',
    CONTENT_TYPE_RESPONSE = 'x-am-content-type-response', // EContentType
    CACHE_CONTROL = 'x-am-cache-control', // ECacheControl
    CODE_HASH = 'x-am-code-hash',
    SANDBOX_TIMEOUT = 'x-am-sandbox-timeout',
    GET_ENCRYPTED_DATA = 'x-am-get-encrypted-data', // EGetEncryptedData
    ENCRYPTED_PAYLOAD = 'x-am-encrypted-payload', // FE will send 'true' in it when payload is encrypted for transfer.
    RESPONSE_CASE = 'x-am-response-case', // EResponseCase
    RESPONSE_OBJECT_TYPE = 'x-am-response-object-type', // EResponseObjectType

}

export enum EGetEncryptedData {
    no_encryption = 'no_encryption',
    get_only_encryption = 'get_only_encryption',
    get_data_and_encryption = 'get_data_and_encryption',
}

export interface IQueryFormat {
    find?: any;
    find_join?: IFindJoinFormat[];
    sort?: any;
    skip?: number;
    limit?: number;
    select?: any;
    deep?: IApiParamsDeepFormat[] | string[] | string; // do not use it, it will return old cached obj because of caching so do not use it.
    set?: any; // It will be used for bulk update.
    groupBy?: any; // it is used in mysql query
    updateData?: any; // it is used in updateMany API
    getTotalCount?: boolean; // return total count if it's value is true.
    [key: string]: any;
}

export interface IApiParamsDeepFormat {
    s_key: string;
    // t_apiPath?: string;
    t_instance?: string;
    t_db?: string;
    t_col?: string;
    t_key?: string;
    deep?: IApiParamsDeepFormat[] | string[] | string;
    select?: any;

    // below are additional feature properties newly added to give more flexibility in populate.
    isMultiple?: boolean;
    find?: any;
    sort?: any;

    // skip & limit can not be supported directly in query, because query is in query with all the ids of array.
    // We process skip & limit while setting multiple data.
    skip?: number;
    limit?: number;
}

export interface IDeepFormat extends IApiParamsDeepFormat {
    // below is used in deep
    selectFromNextDeep?: any;
}

export interface IFindJoinFormat {
    t_instance?: string;
    t_db?: string;
    t_col: string;
    find: any;

    find_key_target?: string; // after find, put list of ids in this key of find // ex: address.country, address
    find_id_source?: string; // get ids from this source key instead of find property // ex: address.country
    sourceTablePrimaryKey?: string; // it will be present when user defines column in source/parent table schema. We will use it instead of child table primary key.
}
