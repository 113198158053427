import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EUserType } from '../enums/EUserType';
import { IUser } from '../interfaces/IUser';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    constructor(private router: Router) {
    }

    gotoDashboard(gUser: IUser) {
        if (!gUser) return;
        switch (gUser.userType) {
            case EUserType.ROOT: {
                this.router.navigate(['/admin-list']);
                break;
            }
            case EUserType.ADMIN: {
                this.router.navigate(['/biller-list']);
                break;
            }
            case EUserType.BILLER: {
                this.router.navigate(['/dashboard/admin']);
                break;
            }
            case EUserType.CLIENT: {
                this.router.navigate(['/trip-management']);
                break;
            }
        }
    }
}
