import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EModelApiPaths } from '../enums/EModelApiPaths';
import { IResponseError } from '../enums/types';
import { AuthService } from '../services/models/auth.service';
import { UserService } from '../services/models/user.service';

@Injectable({ providedIn: 'root' })
export class RBJwtInterceptor implements HttpInterceptor {
    // no need AM and AM_DB token
    doNotAddAuthPaths = [
        `${EModelApiPaths.API}${EModelApiPaths.SYSTEM_API_BASE}${environment.USER_PATH}/token`,
    ];

    // need AM_DB token
    doNotAddAuthUserPaths = [];

    constructor(private router: Router, private authService: AuthService, private userService: UserService) {
        setInterval(async () => {
            await this.userService.refreshToken(); // keep refreshing token every 5 minutes.
        }, 5 * 60_000);
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        const reqUrl = req.url;

        for (const doNotAdd of this.doNotAddAuthPaths) {
            if (reqUrl.indexOf(doNotAdd) === -1) {
                req = this.cloneReqWithHeader(req);
            }
        }

        return next.handle(req).pipe(catchError(error => {
            if (error.status === 401) {
                const errors: IResponseError[] = error && error.error && error.error.errors ? error.error.errors : [];
                if (errors.length && errors[0].message === 'Password changed.') this.authService.logout();
                else if (errors.length && errors[0].message === 'Invalid JWT token provided.') this.authService.logout();
                else if (errors.length && errors[0].message === 'API user not found.') this.authService.logout();
                else {
                    return this.reAuthenticate().pipe(
                        switchMap(() => {
                            req = this.cloneReqWithHeader(req);
                            return next.handle(req);
                        }),
                    );
                }
            } else if (error.status === 400) {
                const errors: IResponseError[] = error && error.error && error.error.errors ? error.error.errors : [];
                if (errors.length && errors[0].message.indexOf('Please provide valid API path') >= 0) this.authService.logout();
            }
            return throwError(error);
        }));
    }

    reAuthenticate(): Observable<any> {
        return fromPromise(
            new Promise<void>(async (resolve, reject) => {
                try {
                    await this.userService.refreshToken();
                    resolve();
                } catch (e) {
                    this.authService.clearLocalStorage();
                    window.location.href = '';
                    reject(e);
                }
            }),
        );
    }

    private cloneReqWithHeader(req: HttpRequest<any>) {
        const headers = {
            // [EHeader.AUTHORIZATION_AM]: this.amCloudUserS.amUser.accessAMToken || '',
            // [EHeader.AUTHORIZATION_AM_USER]: this.authCS.token.accessToken || '',
        };

        return req.clone({ setHeaders: headers });
    }
}
