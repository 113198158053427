import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EUserType } from '../enums/EUserType';
import { AuthService } from '../services/models/auth.service';

@Injectable({
    providedIn: 'root',
})
export class RootG implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.gUser && this.authService.gUser.userType === EUserType.ROOT) {
            return true;
        }
        this.router.navigate(['login']);
        return false;
    }
}
