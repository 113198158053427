import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { AuthService } from '../../../services/models/auth.service';
import { ConstantsService } from '../../../services/constants.service';
import { ENotificationType, INotification, INotification_P, INotification_S } from '../../../interfaces/INotification';
import { IQueryFormat } from '../../../enums/types';
import { RbNotificationService } from '../../../services/models/rb-notification.service';
import { NotificationService } from '../../../services/notification.service';
import * as _ from 'lodash';


@Component({
    selector: 'app-notifications-form-model',
    templateUrl: './notifications-form-model.c.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class notificationsFormModelC implements OnInit {
    loading = { grid: false };
    companyId: string;
    dataItem: INotification;
    isRead: boolean = false;

    rows: INotification[] = [];
    rowsMap: { [_id: string]: INotification } = {};

    // kendo grid
    public selectableSettings: SelectableSettings = { mode: 'single' };
    selectedRows: string[] = [];

    settings = {
        kendoGridPageSize: 100,
        maxRowsLimit: 10000,
    };

    ENotificationType = ENotificationType;


    constructor(
        public authService: AuthService,
        public constantsS: ConstantsService,
        public rbNotificationService: RbNotificationService,
        public ns: NotificationService,
        public cd: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.constantsS.notificationMarkAsReadAll.subscribe(async (data) => {
            if (!data) return;
            await this.loadGridData();
            await this.markAsAllRead();
            this.cd.detectChanges();
        });
    }

    async showModal() {
        $(`#idViewAllNotificationsModel`).modal('show');
        this.isRead = false;
        await this.loadGridData();
        this.cd.detectChanges();
    }

    hideModal() {
        $(`#idViewAllNotificationsModel`).modal('hide');
    }

    async loadGridData() {
        this.loading.grid = true;
        try {
            const query: IQueryFormat = {
                find: <INotification_P>{
                    userId: this.authService.getUserId(),
                    active: true,
                    isRead: false,
                },
                select: <INotification_S>{
                    active: 1,
                    isRead: 1,
                    message: 1,
                    userId: 1,
                    createdAt: 1,
                    type: 1,
                },
                sort: <INotification_S>{ createdAt: -1 },
                limit: this.settings.maxRowsLimit,
            };
            if (this.isRead) query.find.isRead = true;
            const res = await this.rbNotificationService.query<INotification>(query);
            if (res) this.rows = res;
        } catch (e) {
            this.ns.showErrorFromResponse(e.error || e, 'Unable to load grid data.');
        }
        this.loading.grid = false;
        this.cd.detectChanges();
    }

    async markAsRead(event, dataItem: INotification) {
        event.stopPropagation();
        this.loading.grid = true;
        try {
            const res = await this.rbNotificationService.put<INotification>(dataItem._id, { isRead: true }, { select: '_id' });
            if (res) {
                this.ns.showSuccess('success.');
                await this.loadGridData();
                this.constantsS.notification.refreshNotification.next(Math.random());
            }
        } catch (e) {
            this.ns.showErrorFromResponse(e.error || e, 'Unable to read.');
        }
        this.loading.grid = false;
        this.cd.detectChanges();
    }

    async markAsAllRead() {
        this.loading.grid = true;
        try {
            const res = await this.rbNotificationService.updateManyAMCloudNotification<INotification>(<any>{
                find: <INotification_P>{ _id: <any>{ $in: _.map(this.rows, '_id') } },
                updateData: { isRead: true },
            });
            if (res) {
                this.ns.showSuccess('success.');
                await this.loadGridData();
                this.constantsS.notification.refreshNotification.next(Math.random());
            }
        } catch (e) {
            this.ns.showErrorFromResponse(e.error || e, 'Unable to read.');
        }
        this.loading.grid = false;
        this.cd.detectChanges();
    }


}
