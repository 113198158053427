import { EventEmitter, Injectable } from '@angular/core';
import { EAuthTokenType } from '../enums/types';
import { BehaviorSubject, Subject } from 'rxjs';
import { ISignUp_P } from '../interfaces/ISignUp';
import { ISubscription } from 'rxjs-compat/Subscription';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
    TZ_NY = 'America/New_York';
    TZ_IST = 'Asia/Kolkata';
    ISO = 'YYYY-MM-DDTHH:mm:ssZ';
    DATE_FORMAT_MAS: string = 'MM-DD-YYYY';
    DATE_FORMAT_CLAIM: string = 'MM-DD-YYYY hh:mm A';
    DATETIME_FORMAT_USER: string = 'DD-MM-YYYY hh:mm A';
    DATETIME_FORMAT_CYCLE: string = 'MM/DD/YYYY';

    authTokenAM = {
        authTokenType: EAuthTokenType.AM,
        authTokenAM: {
            u: 'full_permission',
            p: 'full_permission',
        },
    };

    chooseBase: ISignUp_P = <any>{};

    baseNameDropDown = {
        baseNameChange: new EventEmitter<any>(),
    };

    notification = {
        refreshNotification: new BehaviorSubject<any>(undefined),
    };

    notificationMarkAsReadAll = new Subject();

    driverList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete driver</b>?</p>',
        removeError: 'Driver removal operation failed.',
        removePermanent: 'Driver permanently removed.',
        syncSuccess: 'Driver sync completed successfully.',
        addSuccess: 'Driver added successfully.',
        editSuccess: 'Driver edited successfully.',
    };

    vehicleList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete Vehicle</b>?</p>',
        removePermanent: 'Vehicle permanently removed.',
        syncSuccess: 'Vehicle sync completed successfully.',
        addSuccess: 'Vehicle added successfully.',
        editSuccess: 'Vehicle edited successfully.',
        removeError: 'Vehicle removal operation failed.',
    };

    uploadedDriverAndVehicleList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete driver & vehicle</b>?</p>',
        removeError: 'Driver & vehicle removal operation failed.',
        removePermanent: 'Driver & vehicle permanently removed.',
        addSuccess: 'Driver & vehicle added successfully.',
        editSuccess: 'Driver & vehicle edited successfully.',
    };

    notImplemented = {
        message: 'This feature is not implemented.',
        downloadMessage: 'You can download it after the modal open.',
    };

    signupList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete base</b>?</p>',
        removePermanentConfirmSecond: '<p class="dialog-text">If you delete base, the base related <span class="text-danger">driver, vehicle, set driver & vehicle and uploaded driver & vehicle</span> will be permanently removed automatically.</p>',
        removePermanent: 'Base permanently removed.',
        removeError: 'Base removal operation failed.',
    };

    adminList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete Admin</b>?</p>',
        removePermanent: 'Admin permanently removed.',
        addSuccess: 'Admin added successfully.',
        editSuccess: 'Admin edited successfully.',
        removeError: 'Admin removal operation failed.',
    };

    billerList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete Biller</b>?</p>',
        removePermanent: 'Biller permanently removed.',
        addSuccess: 'Biller added successfully.',
        editSuccess: 'Biller edited successfully.',
        removeError: 'Biller removal operation failed.',
    };

    trackPaymentList = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">Payment detail permanently delete</b>?</p>',
        removePermanent: 'Payment detail permanently removed.',
        addSuccess: 'Payment detail added successfully.',
        editSuccess: 'Payment detail edited successfully.',
        removeError: 'Payment detail Removal operation failed.',
    };

    cycleCalendar = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete cycle calendar</b>?</p>',
        removeError: 'Cycle Calendar removal operation failed.',
        removePermanent: 'Cycle Calendar permanently removed.',
        addSuccess: 'Cycle Calendar added successfully.',
        editSuccess: 'Cycle Calendar edited successfully.',
    };

    claimTrackings = {
        removePermanentConfirm: '<p class="dialog-text">Do you want to <b class="text-danger">permanently delete Claim Trackings</b>?</p>',
        removeError: 'Claim Trackings removal operation failed.',
        removePermanent: 'Claim Trackings permanently removed.',
        reSubmit276File: 'Re Submit 276 File successfully.',
    };

    constructor() {
    }

    unsubscribe(subscriptions: ISubscription[]) {
        for (const sub of subscriptions) sub.unsubscribe();
    }


}
