import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EFormMode } from '../../enums/EFormMode';
import { IUser, IUser_P } from '../../interfaces/IUser';
import { AuthService } from '../../services/models/auth.service';
import { UserService } from '../../services/models/user.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-user-info-form-model',
    templateUrl: './user-info-form-model.c.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoFormModelC implements OnInit {
    loading = { form: false };
    form: FormGroup;
    callback: Function;
    mode: EFormMode = EFormMode.ADD;
    EFormMode = EFormMode;
    formData: IUser_P = <any>{};

    constructor(private fb: FormBuilder,
                private ns: NotificationService,
                private userService: UserService,
                private authService: AuthService,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group(<IUser_P>{
            email: <any>[null, [Validators.required, Validators.email]],
            name: <any>[null, [Validators.required]],
            mobileNo: <any>[null, [Validators.required]],
            password: <any>[null, [Validators.required]],
        });
    }

    async showModal(mode: string, dataItem: IUser) {
        $(`#idUserInfoFormModel`).modal('show');
        this.formData = dataItem;
        await this.init(mode);
    }

    hideModal() {
        $(`#idUserInfoFormModel`).modal('hide');
    }

    async init(mode) {
        this.loading.form = true;
        this.form.enable();
        if (mode === EFormMode.EDIT) {
            this.mode = EFormMode.EDIT;
            this.form.reset();
            await this.fillUserDataInForm();
        } else {
            this.formData = undefined;
            this.mode = EFormMode.ADD;
            this.form.reset();
        }
        this.loading.form = false;
        this.cd.detectChanges();
    }

    async save() {
        const formData: IUser_P = this.form.getRawValue();
        formData._id = this.formData._id;
        if (formData.name) delete formData.name;
        if (formData.email) delete formData.email;
        try {
            this.loading.form = true;
            const pass = this.form.get('password').value;
            if (formData._id) {
                if (pass) formData.password = pass;
                else delete formData.password;
            }
            const res = await this.userService.updateUser<IUser_P>(formData._id, formData, { select: '_id' });
            if (res) {
                this.ns.showSuccess('User info updated successfully.');
                this.hideModal();
                if (!pass) this.userService.gUser.next({ action: 'REFRESH_G_USER' });
                if (pass) {
                    setTimeout(() => {
                        this.authService.logout();
                    }, 300);
                }
            }
            this.loading.form = false;
        } catch (e) {
            this.loading.form = false;
            this.ns.showErrorFromResponse(e.error || e, 'Unable to save user.');
        }
    }

    async fillUserDataInForm() {
        try {
            this.loading.form = true;
            this.form.reset();
            const resp = await this.userService.getByIdUser<IUser_P>(this.authService.gUser._id);
            this.form.patchValue(resp);
            this.form.get('password').setValidators([]);
            this.form.get('name').disable();
            this.form.get('email').disable();
            this.form.get('password').updateValueAndValidity();
            this.form.get('password').setValue('');
            this.loading.form = false;
        } catch (e) {
            this.loading.form = false;
            this.ns.showErrorFromResponse(e.error || e, 'Unable to fill user data.');
        }
    }
}
