import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ELocalStorage } from '../../enums/ELocalStorage';
import { IUser } from '../../interfaces/IUser';
import { UtilsService } from '../utils.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    gUser: IUser;
    token = {
        accessToken: '',
        refreshToken: '',
    };

    environment: { LICENSE_KEY: string; crypto: { passStoreCommunication: string; } } = <any>{};
    router: Router;

    constructor(public utilsService: UtilsService) {
    }

    setUserToLocalStorage(userObj: IUser, p: string) {
        this.gUser = userObj;
        this.gUser.accessToken = this.token.accessToken;
        this.gUser.refreshToken = this.token.refreshToken;
        const encryptedCode = this.utilsService.getEncryptedString(this.gUser, p);
        localStorage.setItem(ELocalStorage.USER, encryptedCode);
    }

    getUserFromLocalStorage(p: string): IUser {
        const value = localStorage.getItem(ELocalStorage.USER);
        if (value) {
            const decryptedObj = this.utilsService.getDecryptedObject(value, p);
            if (decryptedObj) return decryptedObj;
            else localStorage.setItem(ELocalStorage.USER, '');
        }
    }

    getUserId() {
        const tempUser: IUser = <any>this.gUser; // IAmCloudUser also supported
        if (!tempUser) {
            this.logout();
            window.location.reload();
        }
        return tempUser._id;
    }

    clearLocalStorage() {
        localStorage.clear();
        this.gUser = undefined;
        this.token.accessToken = '';
        this.token.refreshToken = '';
    }

    /**
     * It is common function for BE & STORE both projects.
     */
    logout() {
        this.clearLocalStorage();
        this.router.navigate(['/']);
    }
}
