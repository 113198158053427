import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ECollection } from '../../enums/ECollection';
import { UtilsService } from '../utils.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';
import { UserService } from './user.service';
import { EHeader, IAPIResponse } from '../../enums/types';
import { INotification } from '../../interfaces/INotification';

@Injectable({ providedIn: 'root' })
export class RbNotificationService extends BaseService {
    constructor(public http: HttpClient,
                public utilsService: UtilsService,
                public userService: UserService,
                public authService: AuthService) {
        super(http, userService, utilsService, `/schema/${environment.USER_PATH}/${environment.INS}/${environment.DB}/${ECollection.RB_NOTIFICATIONS}`);
    }

    async countNotification(body: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + this.modelPath + `/count`, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async updateManyAMCloudNotification<T>(body: Partial<T>, queryParams?: any) {
        const resp: IAPIResponse<INotification> = <any>await this.http.put<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/update-many`, body, {
            headers: {
                [EHeader.AUTHORIZATION_AM]: this.userService.amUser.accessAMToken,
                [EHeader.AUTHORIZATION_AM_USER]: this.authService.token.accessToken,
            },
        }).toPromise();
        return resp.data;
    }
}
